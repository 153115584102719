import Link from 'next/link'
import { Hub, SearchResultsHub } from '@/lib/types'
import formatHubData from '@/lib/utils/formatHubData'
import ImageWithArweaveFallbackClient from './ImageWithArweaveFallbackClient'

type HubGridItemProps = {
  hub: Hub | SearchResultsHub
}

export default function HubGridItem({ hub }: HubGridItemProps) {
  const { hubImageString, hubName } = formatHubData(hub)

  const noImage =
    hubImageString === 'https://arweave.net/' ||
    hubImageString === 'https://arweave.net/undefined' ||
    hubImageString === '' ||
    !hubImageString

  return (
    <div key={hub.publicKey} className="h-full">
      <Link href={`/hubs/${hub.handle}`}>
        {noImage && (
          <div className="max-h-[353px] min-h-[160px] w-full min-w-[160px]">
            <div className="aspect-h-1 aspect-w-1 max-h-[353px] min-h-[160px]">
              <div className="imageBorder box-border w-full bg-white" />
            </div>
          </div>
        )}
        {!noImage && (
          <ImageWithArweaveFallbackClient
            src={hubImageString}
            alt={hubName}
            width={260}
            height={260}
            className="imageBorder box-border w-full hover:opacity-80"
            priority
          />
        )}
      </Link>

      <Link
        href={`/hubs/${hub.handle}`}
        className="body-1 mt-8 line-clamp-1 max-h-[40px] min-h-[20px] overflow-hidden text-blue-82 hover:opacity-80 md:mt-12"
      >
        {hubName}
      </Link>
    </div>
  )
}
