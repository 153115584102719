'use client'

import clsx from 'clsx'
import Link from 'next/link'
import {
  MouseEvent,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import ArchivedFlag from '@/app/(main)/releases/[releasePublicKey]/components/ArchivedFlag'
import Icon from '@/components/tokens/Icon'
import Audio from '@/contexts/AudioContext'
import {
  ButtonVariant,
  FavoriteButtonVariant,
  FavoriteType,
  HubRelease,
  ListBoxView,
  PublicKeyString,
  QueueItem,
  Release,
  ReleaseListItemVariant,
  ScheduledRelease,
  View,
} from '@/lib/types'
import ImagePlaceholderBase64 from '@/lib/utils/ImagePlaceholderBase64'
import { formatDuration } from '@/lib/utils/formatDuration'
import formatReleaseData from '@/lib/utils/formatReleaseData'
import { formatYYYYMMDD } from '@/lib/utils/formatYYYYMMDD'
import { useReleaseAccess } from '@/lib/utils/useReleaseAccess'
import CollectedFlag from './CollectedFlag'
import FavoriteButton from './FavoriteButton'
import ImageWithArweaveFallbackClient from './ImageWithArweaveFallbackClient'
import ReleaseListBox from './ReleaseListBox'
import Button from './tokens/Button'

type ReleaseListItemProps = {
  release: Release | ScheduledRelease
  paddingOverride?: string
  variant?: ReleaseListItemVariant
  index?: number
  isHubAuthority?: boolean
  hubPublicKey?: PublicKeyString
}

function ReleaseListItem({
  release,
  paddingOverride,
  variant = ReleaseListItemVariant.Default,
  index,
  isHubAuthority,
  hubPublicKey,
}: ReleaseListItemProps) {
  const [showReleaseTracks, setshowReleaseTracks] = useState(false)

  const {
    playRelease,
    isPlaying,
    activeQueueItem,
    pauseResume,
    playQueueItemAtIndex,
  } = useContext(Audio.Context)

  const { hasAccess } = useReleaseAccess(release)
  const hasMetadata = 'metadata' in release && release.metadata
  const forceImageRef = useRef(null)
  const [forceShowImage, setForceShowImage] = useState(false)
  const [releaseIsPlaying, setReleaseIsPlaying] = useState(false)
  const isArchived = release.archived && !hasAccess
  useEffect(() => {
    if (
      isPlaying &&
      activeQueueItem()?.release?.publicKey === release.publicKey
    ) {
      setReleaseIsPlaying(true)
    } else {
      setReleaseIsPlaying(false)
    }
  }, [isPlaying, activeQueueItem()]) //concerned about having function in dep array

  if ((release as HubRelease).visible === false && !isHubAuthority) {
    return null
  }

  if (!release) return null

  const { releaseLinkString, releaseImageString, releaseTitleString } =
    formatReleaseData(release)

  const handleShowReleaseTracks = (e: MouseEvent) => {
    e.stopPropagation()
    setshowReleaseTracks(!showReleaseTracks)
  }

  const onMouseEnter = () => {
    setForceShowImage(true)
  }

  const onMouseLeave = () => {
    setForceShowImage(false)
  }

  const handlePlayPauseRelease = (e: MouseEvent) => {
    e.stopPropagation()

    if (forceShowImage) return //This handles logic to make the ReleaseListItem play on click with certain elements excluded -- potentially could have better name -- will be refactored to use propagation events

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    if (activeQueueItem()?.release?.publicKey === release.publicKey) {
      //pauseResume active release
      pauseResume()

      return
    }

    if (variant === ReleaseListItemVariant.Embed) {
      if (index !== undefined) {
        //When playing a Release in an embeded hub, this allows us to play and maintain accurate activeIndex
        playQueueItemAtIndex(release, index, true)
      }
    } else {
      playRelease(release) //play new release
    }
  }

  return (
    <>
      <li className="border-b-1 border-grey-10 last-of-type:border-none">
        {isArchived && (
          <div className="my-12 flex h-40 w-full items-center bg-grey-3 ">
            <p className="ui-1 ml-12 cursor-default select-none text-grey-45">
              Archived release
            </p>
          </div>
        )}
        {!isArchived && (
          <div
            className={clsx(
              'body-1 group flex justify-between  py-12 ',
              paddingOverride,
            )}
            onClick={(e) => {
              e.stopPropagation

              if (!release.publicKey) return

              handlePlayPauseRelease(e)
            }}
          >
            <div className="flex pr-12 ">
              <div
                className={clsx(
                  ' relative mr-12 !h-[40px] min-w-[40px]  cursor-pointer',
                  !release.publicKey &&
                    'disableHighlight pointer-events-none touch-none',
                )}
              >
                <ImageWithArweaveFallbackClient
                  alt={releaseTitleString}
                  src={releaseImageString}
                  width={40}
                  height={40}
                  className={clsx(
                    hasMetadata &&
                      !forceShowImage &&
                      release.publicKey &&
                      'md:group-hover:hidden',
                    'imageBorder flex  !h-40 !w-40',
                    releaseIsPlaying && 'hidden',
                    showReleaseTracks && '!flex',
                  )}
                  placeholder="blur"
                  blurDataURL={ImagePlaceholderBase64}
                />
                {hasMetadata && release.publicKey && (
                  <span
                    className={clsx(
                      'absolute left-1/2 top-1/2 hidden  h-[40px] w-[40px] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center border-1 border-grey-10 md:group-hover:flex',
                      forceShowImage && !releaseIsPlaying && '!hidden',
                      releaseIsPlaying && !showReleaseTracks && '!flex',
                      showReleaseTracks && '!hidden',
                    )}
                  >
                    <Button
                      variant={ButtonVariant.Unstyled}
                      icon={releaseIsPlaying ? 'pauseSmall' : 'playSmall'}
                      name={releaseIsPlaying ? 'pauseSmall' : 'playSmall'}
                      className="black"
                      onClick={(e) => handlePlayPauseRelease(e)}
                    />
                  </span>
                )}
              </div>
              <div className="">
                <div
                  className={clsx(
                    'w-fit',
                    !release.publicKey && 'disableHighlight',
                  )}
                  ref={forceImageRef}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Link
                    href={`/releases/${releaseLinkString}`}
                    target={
                      variant === ReleaseListItemVariant.Embed ? '_blank' : ''
                    }
                    className={clsx(
                      'line-clamp-1 w-fit  text-black hover:text-opacity-80',
                      !release.publicKey && 'pointer-events-none touch-none ',
                    )}
                  >
                    {releaseTitleString}
                  </Link>
                </div>

                {hasMetadata && release.hub && (
                  <div
                    className="w-fit "
                    ref={forceImageRef}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Link
                      href={`/hubs/${release.hub.handle}`}
                      target={
                        variant === ReleaseListItemVariant.Embed ? '_blank' : ''
                      }
                      className=" line-clamp-1 w-fit text-blue-82 hover:text-opacity-80"
                    >
                      {release.hub.data.displayName}
                    </Link>
                  </div>
                )}

                {hasMetadata && !release.hub && release.publisherAccount && (
                  <div
                    className="w-fit"
                    ref={forceImageRef}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Link
                      href={`/profiles/${release.publisherAccount.handle}`}
                      target={
                        variant === ReleaseListItemVariant.Embed ? '_blank' : ''
                      }
                      className=" line-clamp-1 w-fit text-grey-45 hover:text-opacity-80"
                    >
                      {release.publisherAccount.displayName}
                    </Link>
                  </div>
                )}
                <div className="hidden md:flex">
                  <CollectedFlag release={release} view={View.List} />
                  <ArchivedFlag
                    release={release}
                    view={View.List}
                    hasAccess={hasAccess}
                  />
                </div>
              </div>
            </div>

            <div className=" flex max-w-[250px] items-start justify-between gap-x-12 md:gap-x-28">
              <div
                className="hidden md:flex"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <FavoriteButton
                  publicKey={release.publicKey}
                  favoriteType={FavoriteType.Release}
                  favoriteButtonVariant={FavoriteButtonVariant?.ReleaseList}
                  title={releaseTitleString}
                  source="releaseList"
                />
              </div>

              {hasMetadata && (
                <p className="ui-1-tabular mt-[2px] hidden whitespace-nowrap text-right tabular-nums sm:w-[81px] md:block">
                  {formatYYYYMMDD(release?.datetime)}
                </p>
              )}

              {'releaseDate' in release && release.releaseDate && (
                <p className="ui-1-tabular mt-[2px] whitespace-nowrap text-grey-45">
                  {new Date(release.releaseDate).toLocaleString()}
                </p>
              )}

              {hasMetadata && release.metadata.properties.files.length > 1 && (
                <div className="w-[46px] text-right">
                  <button
                    onClick={(e) => handleShowReleaseTracks(e)}
                    className=""
                  >
                    <Icon
                      className="black cursor-pointer"
                      height={20}
                      width={20}
                      name={
                        showReleaseTracks
                          ? 'chevronUpHeavy'
                          : 'chevronDownHeavy'
                      }
                    />
                  </button>
                </div>
              )}
              {hasMetadata && release.metadata.properties.files.length < 2 && (
                <p className="ui-1-tabular mt-[2px] hidden min-w-[46px] text-right  sm:block	">
                  {formatDuration(
                    release.metadata.properties.files[0].duration,
                  )}
                </p>
              )}
              {hasMetadata &&
                variant !== ReleaseListItemVariant.Embed &&
                release.publicKey && (
                  <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <ReleaseListBox
                      release={release}
                      view={ListBoxView.List}
                      isHubAuthority={isHubAuthority}
                      hubPublicKey={hubPublicKey}
                    />
                  </div>
                )}
            </div>
          </div>
        )}
      </li>

      {hasMetadata &&
        showReleaseTracks &&
        release.metadata.properties.files.length > 1 && (
          <ul className="w-full border-b-1 !border-grey-10">
            {release.metadata.properties.files.map((track) => {
              return releaseTrackItem(
                track.track_title,
                track.duration,
                track.track,
                release,
                releaseIsPlaying,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                activeQueueItem,
                pauseResume,
                playRelease,
                isHubAuthority,
                hubPublicKey,
              )
            })}
          </ul>
        )}
    </>
  )
}

//individual tracks in release

function releaseTrackItem(
  name: string,
  duration: number,
  index: number,
  release: Release,
  releaseIsPlaying: boolean,
  activeQueueItem: () => QueueItem,
  pauseResume: () => void,
  playRelease: (release: Release, index?: number) => void,
  isHubAuthority: boolean,
  hubPublicKey: PublicKeyString,
) {
  let trackIsActiveQueueItem = false
  const activeQueueClone = activeQueueItem()

  if (releaseIsPlaying && activeQueueClone.trackNumber === index) {
    trackIsActiveQueueItem = true
  }

  const handlePlayPauseTrack = (e: MouseEvent) => {
    e.stopPropagation()

    if (trackIsActiveQueueItem) {
      pauseResume()

      return
    }

    playRelease(release, index - 1)
  }

  return (
    <li
      onClick={(e) => handlePlayPauseTrack(e)}
      className="body-1 group ml-[52px] flex justify-between border-b-1 border-grey-10 py-8 last-of-type:border-none"
    >
      <div className="flex items-center pr-12">
        <div className="flex w-[32px] cursor-pointer items-start justify-start pr-28">
          <p
            className={clsx(
              'ui-1-tabular flex   md:group-hover:hidden',
              trackIsActiveQueueItem && '!hidden',
            )}
          >
            {index}
          </p>
          <div
            className={clsx(
              'hidden md:group-hover:flex',
              trackIsActiveQueueItem && '!flex',
            )}
          >
            <Icon
              name={trackIsActiveQueueItem ? 'pauseLeftSmall' : 'playLeftSmall'}
              className="black"
              height={16}
              width={16}
            />
          </div>
        </div>
        <p className="line-clamp-2 cursor-pointer overflow-hidden">{name}</p>
      </div>
      <div className="flex">
        <p className="ui-1-tabular hidden items-center pr-28 tabular-nums md:flex">
          {formatDuration(duration)}
        </p>
        <div>
          <ReleaseListBox
            release={release}
            index={index - 1}
            isTrack={true}
            view={ListBoxView.List}
            isHubAuthority={isHubAuthority}
            hubPublicKey={hubPublicKey}
          />
        </div>
      </div>
    </li>
  )
}

export default memo(ReleaseListItem)
