import clsx from 'clsx'
import Link from 'next/link'
import { HubPost, Post, ScheduledPost } from '@/lib/types'
import emptyArticle from '@/public/assets/empty-article.png'
import ImageWithArweaveFallbackClient from './ImageWithArweaveFallbackClient'

type PostGridItemProps = {
  post: Post | HubPost | ScheduledPost
  featured?: boolean
}

export default function PostGridItem({ post, featured }: PostGridItemProps) {
  const imageLink = post.data?.heroImage
  const isScheduled = 'releaseDate' in post && post.releaseDate

  const PostGridItemComponent = () => {
    return (
      <Link href={`/articles/${post.data.slug}`}>
        <div className="aspect-h-9 aspect-w-16">
          <ImageWithArweaveFallbackClient
            alt={post.data.title}
            className="imageBorder w-full object-cover hover:opacity-70"
            src={imageLink ? imageLink : emptyArticle}
            height={featured ? 398 : 199}
            width={featured ? 706 : 353}
            priority
            loading="eager"
          />
        </div>
        <p
          className={clsx(
            'mt-8 line-clamp-2 max-h-[40px] min-h-[20px] md:mt-12 md:hover:opacity-70',
            featured ? 'display-1 max-h-[64px]' : 'body-1',
          )}
        >
          {post.data.title}
        </p>
      </Link>
    )
  }

  return (
    <div key={post.publicKey} className="body-1 h-full flex-1">
      <PostGridItemComponent />
      {post.hub && (
        <Link href={`/hubs/${post.hub.handle}`}>
          <p
            className={clsx(
              'line-clamp-1 max-h-[40px] overflow-hidden text-blue-82 md:hover:opacity-80',
              featured ? 'display-1' : 'body-1',
            )}
          >
            {post.hub.data.displayName}
          </p>
        </Link>
      )}
      {isScheduled && (
        <p className="body-1 text-grey-45">
          {new Date(post.releaseDate).toLocaleString()}
        </p>
      )}
    </div>
  )
}
